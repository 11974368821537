import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

interface CheckMarkProps {}

const CheckMark: React.FC<CheckMarkProps> = () => {
  return (
    <div
      id="CheckMark"
      className="bg-titan flex h-[32px] w-[32px] items-center justify-center rounded-full"
    >
      <FontAwesomeIcon icon={faCheck} />
    </div>
  )
}

export default CheckMark
