'use client'

import Modal from '@codeverse/helios/modal'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import { useRouter } from 'next/navigation'
import { useEffect, useMemo } from 'react'
import BadgeModal from './BadgeModal'
import useModalDataStore from 'apps/studio-shared/src/Stores/UseModalDataStore'
import useBadgeStore from 'apps/studio-shared/src/Stores/useBadgeStore'

export default function BadgesModal() {
  const router = useRouter()
  const { badgeModal, setBadgeModal } = useUIStore()
  const { setCurrentBadge } = useBadgeStore()
  const handleClose = () => {
    setBadgeModal(false)
    setCurrentBadge(null)
  }

  useEffect(() => {
    return () => {
      setBadgeModal(false)
    }
  }, [])

  return (
    <Modal
      width={'616px'}
      height={'435px'}
      onClose={handleClose}
      isOpen={badgeModal}
      contentContainerClassname="!overflow-hidden"
      allowEsc
    >
      <BadgeModal />
    </Modal>
  )
}
