//@ts-nocheck
import React, { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faLock } from '@fortawesome/pro-solid-svg-icons'

import BadgeConditionList from './BadgeConditionList'
import {
  useBadgeAchievements,
  useCurriculumAccomplishments,
} from 'apps/studio-shared/src/Data/Achievements/BadgeDataHooks'
import { getActivity } from 'apps/studio-shared/src/Data/Achievements/BadgeFetch'
import { useQueries } from '@tanstack/react-query'

interface BadgeLevelDescriptionProps {
  badge: any
  index: any
  badgeList: any
  setCurrentOpenBadgeId: any
  currentOpenBadgeId: any
}

const pointsContainerClassnames = ''

const CompletedPoints = ({ points }) => {
  return (
    <div className="flex w-2/5 items-center justify-end">
      <div className="bg-titan h-[36px] min-w-[96px] rounded-3xl text-white">
        <div className="flex h-full min-w-[96px] flex-row">
          <div className="mx-2 mr-2 flex items-center justify-center">
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <div className="font-poppins-bold flex w-auto select-none items-center justify-center whitespace-nowrap pl-[2px] pr-[12px] text-sm">
            {`${points} Pts`}
          </div>
        </div>
      </div>
    </div>
  )
}
const UncompletedPoints = ({ points }) => {
  return (
    <div className="flex w-2/5 items-center justify-end">
      <div className="bg-moon-light text-moon-dark h-[36px] min-w-[96px] rounded-3xl">
        <div className="font-poppins-bold flex h-full w-full items-center justify-center text-center">{`${points} Pts`}</div>
      </div>
    </div>
  )
}

const LockedPoints = ({ points }) => {
  return (
    <div className="flex w-2/5 items-center justify-end">
      <div className="bg-moon-light text-moon-dark h-[36px] min-w-[96px] rounded-3xl">
        <div className="m-w-[96px] flex h-full flex-row">
          <div className="mx-3 flex items-center justify-center">
            <FontAwesomeIcon icon={faLock} />
          </div>
          <div className="font-poppins-bold flex w-auto items-center pl-[2px] pr-[12px] text-base">
            {`${points} Pts`}
          </div>
        </div>
      </div>
    </div>
  )
}

const BadgeLevelDescription: React.FC<BadgeLevelDescriptionProps> = ({
  badge,
  index,
  badgeList,
  setCurrentOpenBadgeId,
  currentOpenBadgeId,
}) => {
  const badgeLevelDescriptionClassnames =
    'py-[18px] px-6 flex flex-row cursor-pointer'
  const [showActivities, setShowActivities] = useState(false)

  const { data: badgeAchievements } = useBadgeAchievements()
  const { data: curriculumAccomplishments } = useCurriculumAccomplishments()

  const queries = useMemo(() => {
    return (
      badge?.conditions_tree?.children[0]?.activities?.map((activity: any) => {
        return {
          queryKey: ['activity', activity.activity_id],
          queryFn: () => getActivity(activity.activity_id),
        }
      }) || []
    )
  }, [badge])

  const badgeConditionActivitiesData = useQueries({
    queries,
  })

  const badgeConditionActivities: any = useMemo(() => {
    return badgeConditionActivitiesData?.map((badgeConditionActivityData) => {
      return badgeConditionActivityData.data
    })
  }, [badgeConditionActivitiesData])

  const badgeAchievement = useMemo(() => {
    return badgeAchievements?.me.badgeAchievements?.find(
      (badgeAchievement: any) => badgeAchievement.badge.id === badge.id
    )
  }, [badgeAchievements])

  const PointsCap = useMemo(() => {
    if (badgeAchievement && badge) {
      return <CompletedPoints points={badge.total_points} />
    } else if (showActivities) {
      return <UncompletedPoints points={badge.total_points} />
    } else {
      return <LockedPoints points={badge.total_points} />
    }
  }, [badgeAchievement, badge, showActivities])

  const CoinsCap = useMemo(() => {
    return (
      <div
        id="TopSection_CoinsPill"
        className="flex w-2/5 items-center justify-end text-white"
        style={{ height: 40 }}
      >
        <div className="bg-moon-darkest leading-14 relative flex flex-row items-center rounded-full px-1 py-1 pl-[4px] pr-[10px] text-base font-bold text-white">
          <img
            className="mr-2 h-[28px]"
            alt="coin icon"
            src={'/images/studio/coin.svg'}
          />
          + {badge.coins}
        </div>
      </div>
    )
  }, [badgeAchievement, badge, showActivities])

  const badgeConditions = useMemo(() => {
    if (badge.conditions_tree) {
      const list = []
      const comparator = badge.conditions_tree.children[0].comparator

      badgeConditionActivities?.map((badgeConditionActivity, index) => {
        if (badgeConditionActivity) {
          const hasCurriculumAccomplishment = curriculumAccomplishments?.find(
            (curriculumAccomplishment) => {
              return (
                curriculumAccomplishment.curriculum_activity.id ===
                badgeConditionActivity.id
              )
            }
          )
          const activity = badge.conditions_tree.children[0].activities.find(
            (act) => badgeConditionActivity.id === act.activity_id
          )
          const count = badgeConditionActivity.count
          let hint = null
          let processor = null
          if (badgeConditionActivity.hint) {
            hint = badgeConditionActivity.hint
          }
          if (badgeConditionActivity.processor) {
            processor = badgeConditionActivity.processor
          }
          !hasCurriculumAccomplishment
            ? list.push({
                name: badgeConditionActivity.name,
                comparator,
                count: activity.count,
                hint,
                processor,
              })
            : list.push({
                name: badgeConditionActivity.name,
                hasCurriculumAccomplishment,
                count: activity.count,
                hint,
                processor,
              })
        }
      })
      return list
    }
    return []
  }, [badge, badgeConditionActivities, curriculumAccomplishments])

  const firstBadge = index === 0
  const previousBadge = !firstBadge ? badgeList[index - 1] : null
  const previousHasBadgeAchievement = useMemo(() => {
    return previousBadge
      ? badgeAchievements?.me.badgeAchievements.find(
          (badgeAchievement) => badgeAchievement.badge.id === previousBadge.id
        )
      : null
  }, [previousBadge, badgeAchievements])

  const badgeHeaderClassnames = classNames(
    'mb-0 font-jakarta-sans font-bold text-lg select-none cursor-pointer',
    {
      'opacity-40': (badgeAchievement || !badgeAchievement) && !showActivities,
    }
  )

  useEffect(() => {
    if (currentOpenBadgeId !== badge.id) {
      setShowActivities(false)
    }
  }, [currentOpenBadgeId])

  useEffect(() => {
    if (firstBadge && !badgeAchievement) {
      setShowActivities(true)
    } else if (previousBadge) {
      if (previousHasBadgeAchievement && !badgeAchievement) {
        setShowActivities(true)
      }
    }
  }, [badgeAchievement, firstBadge, previousBadge, previousHasBadgeAchievement])

  const comparatorValue = useMemo(() => {
    return badge?.conditions_tree?.children[0].comparator
  }, [badge])

  return (
    <div
      id="BadgeLevelDescription"
      className="border-moon-light cursor-pointer border-b border-opacity-40"
      onClick={() => {
        if (badgeAchievement) {
          setCurrentOpenBadgeId(badge.id)
          setShowActivities(!showActivities)
        }
      }}
    >
      <div
        id="BadgeLevelDescription_Header"
        className={badgeLevelDescriptionClassnames}
      >
        <div className="flex w-3/5 items-center">
          <h4 className={badgeHeaderClassnames}>{badge.name}</h4>
        </div>
        {badge && badge.coins > 0 ? CoinsCap : PointsCap}
      </div>
      {showActivities && (
        <p className="font-jakarta-sans px-6 text-sm font-semibold text-[#090E2B]">
          {badge.description}
        </p>
      )}
      <BadgeConditionList
        showActivities={showActivities}
        comparatorValue={comparatorValue}
        badgeAchievement={badgeAchievement}
        badgeConditions={badgeConditions}
      />
    </div>
  )
}

export default BadgeLevelDescription
