import React from 'react'

import BadgeConditions from './BadgeConditions'
import CheckMark from './CheckMark'

interface BadgeConditionListProps {
  showActivities: boolean
  comparatorValue: string
  badgeAchievement: any
  badgeConditions: any
}

const ovalClassnames = 'bg-moon-light h-[20px] rounded-full w-[20px]'
const badgeContainerClassname =
  'font-bold items-center pl-2 pr-2 py-[12px] text-moon-dark w-full flex flex-auto'

const BadgeConditionList: React.FC<BadgeConditionListProps> = ({
  showActivities,
  comparatorValue,
  badgeAchievement,
  badgeConditions,
}) => {
  return (
    <div id="BadgeConditionList">
      {showActivities && (
        <div className="transition-all duration-300">
          {comparatorValue === 'or' ? (
            <div className="bg-moon-light rounded-4xl mb-4 mt-4 flex min-h-[56px] flex-row">
              <div className={badgeContainerClassname}>
                <BadgeConditions
                  badgeAchievement={badgeAchievement}
                  badgeConditions={badgeConditions}
                />
              </div>
            </div>
          ) : (
            <>
              {badgeConditions.map((badgeCondition, i) => {
                return (
                  <div
                    key={i}
                    className="mx-4 mb-4 mt-4 flex min-h-[56px] flex-row rounded-lg bg-[#E9EFF8]"
                  >
                    <div className={badgeContainerClassname}>
                      <BadgeConditions badgeConditions={[badgeCondition]} />
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default BadgeConditionList
