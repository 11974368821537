import {
  faChevronCircleDown,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

import CheckMark from './CheckMark'

interface BadgeConditionProps {
  badgeCondition: any
  badgeAchievement: any
  index: number
}

const ovalClassnames = 'bg-white h-[20px] rounded-full w-[20px]'

const BadgeCondition: React.FC<BadgeConditionProps> = ({
  badgeCondition,
  badgeAchievement,
  index,
}) => {
  const [showHint, setShowHint] = useState(false)
  const [toolTipOpen, setToolTipOpen] = useState(false)

  const toolTipStyle = {
    color: '#ffffff',
    backgroundColor: '#000000',
  }

  return (
    <div
      id="BadgeCondition"
      key={badgeCondition.name}
      className="badge-condition cursor-pointer select-none"
      onClick={() => {
        if (badgeCondition.hint) {
          setShowHint(!showHint)
        }
      }}
    >
      {badgeCondition.comparator &&
        badgeCondition.comparator === 'or' &&
        index !== 0 && (
          <div className="relative w-full px-3 text-xs">
            <span className="operator__line">OR</span>
          </div>
        )}
      <div style={{ display: 'flex' }}>
        <div
          className={
            'font-jakarta-sans flex w-full items-center font-semibold leading-4 text-[#090E2B]'
          }
          style={{ width: '90%' }}
        >
          <div className="h-full">
            <div className="mr-2 inline-block">
              {badgeCondition.comparator === 'or' && (
                <>
                  {badgeAchievement ? (
                    <CheckMark />
                  ) : (
                    <div className={ovalClassnames} />
                  )}
                </>
              )}
              {badgeCondition.comparator !== 'or' && (
                <>
                  {badgeCondition.hasCurriculumAccomplishment &&
                  badgeCondition.hasCurriculumAccomplishment.count >=
                    badgeCondition.count ? (
                    <CheckMark />
                  ) : (
                    <div className={ovalClassnames} />
                  )}
                </>
              )}
            </div>
          </div>
          <div className="select-none">{badgeCondition.name}</div>
          <strong className="ml-auto mr-2">
            {badgeCondition.count && badgeCondition.count > 1
              ? `x${badgeCondition.count}`
              : ''}
          </strong>
        </div>

        <div style={{ width: '10%' }} className="relative ml-1 mt-3 h-full">
          {badgeCondition.processor &&
            badgeCondition.processor === 'manual' && (
              <>
                <i
                  id={`toolTipTarget${badgeCondition.name.split(' ')[0]}`}
                  className="fa-user-astronaut fas"
                />
                {/* <Tooltip style={toolTipStyle} placement="top" isOpen={toolTipOpen} target={`toolTipTarget${badgeCondition.name.split(' ')[0]}`} toggle={() => setToolTipOpen(!toolTipOpen)}>
              Show A Guide
              </Tooltip> */}
              </>
            )}
          {badgeCondition.hint &&
            (showHint ? (
              <FontAwesomeIcon
                className="absolute right-2 top-1/2 -translate-y-1/2 transform cursor-pointer pr-1 text-[#000000]"
                size="lg"
                id="BadgeCondition_HideHint"
                icon={faChevronUp}
                onClick={() => setShowHint(false)}
              />
            ) : (
              <FontAwesomeIcon
                id="BadgeCondition_ShowHint"
                className="absolute right-2 top-1/2 -translate-y-1/2 transform cursor-pointer pr-1 text-[#000000]"
                size="lg"
                icon={faChevronDown}
                onClick={() => setShowHint(true)}
              />
            ))}
        </div>
      </div>
      <div
        className={`font-jakarta-sans mt-3 w-full rounded-xl bg-white px-4 py-2 font-medium ${
          showHint ? 'inline-block' : 'hidden'
        }`}
      >
        {badgeCondition.hint}
      </div>
    </div>
  )
}

export default BadgeCondition
