'use client'
import React, { useEffect, useMemo, useState } from 'react'
import {
  useBadgeAchievements,
  useBadges,
} from 'apps/studio-shared/src/Data/Achievements/BadgeDataHooks'
import useBadgeStore from 'apps/studio-shared/src/Stores/useBadgeStore'
import { useNavigate } from 'react-router'
import className from 'classnames'
import BadgeLevelDescription from './BadgeLevelDescription'
import ConfettiExplosion from 'react-confetti-explosion'
import { HeliosPill } from '@codeverse/helios'
import { AnimatePresence, motion } from 'framer-motion'
import moment from 'moment-timezone'
import MiniBadge from './MiniBadge'

export default function BadgeModal() {
  const { currentBadge } = useBadgeStore()
  const { data: badgeAchievements } = useBadgeAchievements()
  const { data: badges } = useBadges()
  const [isHovering, setIsHovering] = useState(false)
  const [currentOpenBadgeId, setCurrentOpenBadgeId] = useState(null)

  const badgeList = useMemo(() => {
    if (!currentBadge) return null
    const badgeToFind = badges?.find((badge) => badge.id === currentBadge?.id)
    const firstBadgeId = badgeToFind?.first_badge?.id
    const badgeTree = badges?.filter(
      (badge) => badge.first_badge.id === firstBadgeId
    )

    const sortedBadgeTree = badgeTree?.sort((a, b) => a.position - b.position)
    return sortedBadgeTree
  }, [currentBadge, badges])

  const BadgeList = useMemo(() => {
    return badgeList?.map((badgeIterator, index) => {
      return (
        <MiniBadge
          badgeAchievements={badgeAchievements}
          badgeIterator={badgeIterator}
          index={index}
          badgeList={badgeList}
        />
      )
    })
  }, [badgeList, badgeAchievements])

  const BadgeLevelDescriptions = useMemo(() => {
    return badgeList?.map((badge, index) => {
      return (
        <BadgeLevelDescription
          badge={badge}
          badgeList={badgeList}
          currentOpenBadgeId={currentOpenBadgeId}
          setCurrentOpenBadgeId={setCurrentOpenBadgeId}
          index={index}
          key={index}
        />
      )
    })
  }, [badgeList])

  const badge = useMemo(() => {
    return badges?.find(
      (badge: any) => badge.id === (currentBadge?.id || currentBadge?.id)
    )
  }, [badges, currentBadge])

  const badgeAchievement = useMemo(() => {
    const badgeAchievement = badgeAchievements?.me?.badgeAchievements?.find(
      (ba) => badge.id === ba.badge.id
    )
    return badgeAchievement
  }, [badges, currentBadge, badgeAchievements])

  // useEffect(() => {
  //   if (badge) {
  //     setTimeout(() => {
  //       setIsHovering(true)
  //     }, 400)
  //   } else {
  //     setIsHovering(false)
  //   }
  // }, [badge])

  if (!badge) return null

  return (
    <div className="flex h-full w-full flex-col overflow-y-scroll transition-all duration-150 lg:flex-row lg:overflow-hidden">
      <div className="bg-nebula-lightest relative w-full lg:w-1/2">
        <div className="mt-6 px-4 text-center lg:mt-9">
          <h3 className="select-none">{badge.name}</h3>
        </div>
        <div
          className="lg:mt-17 relative mx-auto mt-4 flex cursor-pointer items-center justify-center"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <img
            style={{ width: '240px', height: '261px' }}
            src={badge.image}
            alt={badge.name}
          />
          {isHovering && (
            <ConfettiExplosion
              className="absolute left-1/2 -translate-x-1/2 transform"
              zIndex={9999}
            />
          )}
          {isHovering && (
            <AnimatePresence>
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="absolute bottom-0"
              >
                <HeliosPill context="dark">
                  Earned {moment(badgeAchievement?.createdAt).fromNow()}
                </HeliosPill>
              </motion.div>
            </AnimatePresence>
          )}
        </div>

        <div className="relative bottom-6 mt-8 flex h-[52px] w-full flex-row justify-center lg:absolute lg:mt-0">
          {BadgeList}
        </div>
      </div>
      <div className="w-full overflow-visible lg:w-1/2 lg:overflow-y-scroll">
        {BadgeLevelDescriptions}
      </div>
    </div>
  )
}
