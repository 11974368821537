'use client'
import { HeliosPill } from '@codeverse/helios'
import className from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import moment from 'moment-timezone'
import { useState } from 'react'
import ConfettiExplosion from 'react-confetti-explosion'

export default function MiniBadge({
  badgeAchievements,
  badgeIterator,
  index,
  badgeList,
}: {
  badgeAchievements: any
  badgeIterator: any
  index: number
  badgeList: any
}) {
  const [isHovering, setIsHovering] = useState(false)

  const badgeAchievement = badgeAchievements?.me?.badgeAchievements?.find(
    (ba) => ba.badge.id === badgeIterator.id
  )

  const tempClassnames = className('', {
    'mr-2': index !== badgeList.length - 1,
    'cursor-pointer': badgeAchievement,
    'grayscale opacity-60': !badgeAchievement,
  })
  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <img
        key={index}
        id="BadgeDetailModal_BadgeImage"
        onClick={() => {
          if (badgeAchievement) {
            // setCurrentBadgeId(badgeIterator.id)
            // openUI('badgeDetailModal')
            // navigate(`/home/badges/${badgeIterator.id}`)
          }
        }}
        className={tempClassnames}
        src={badgeIterator.image}
        alt={badgeIterator.name}
        style={{ height: '52px', width: '44px' }}
      />
      {/* {isHovering && (
        <AnimatePresence>
          <motion.div
            initial={{ y: 0, opacity: 0 }}
            animate={{ y: 5, opacity: 1 }}
            className="absolute -left-1/2 bottom-[-20px] z-[9999] w-auto min-w-[150px]"
          >
            <HeliosPill className="w-full select-none" context="dark">
              Earned {moment(badgeAchievement?.createdAt).fromNow()}
            </HeliosPill>
          </motion.div>
        </AnimatePresence>
      )} */}
      {/* {isHovering && (
        <ConfettiExplosion
          className="absolute left-[22px] top-0  transform"
          height={64}
          width={54}
          particleSize={2}
          zIndex={9999}
        />
      )} */}
    </div>
  )
}
