import React from 'react'
import BadgeCondition from './BadgeCondition'

const BadgeConditions = ({ badgeConditions, badgeAchievement }: any) => {
  return (
    <div id="BadgeConditions" className="badge-conditions w-full">
      {badgeConditions ? (
        badgeConditions.map((badgeCondition: any, index: any) => {
          return (
            <BadgeCondition
              badgeAchievement={badgeAchievement}
              badgeCondition={badgeCondition}
              index={index}
              key={index}
            />
          )
        })
      ) : (
        <></>
      )}
    </div>
  )
}

export default BadgeConditions
